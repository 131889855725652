import { Component, OnInit, Input } from '@angular/core';
import { FolderListService } from './../folder-list/folder-list.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'folder-details',
  templateUrl: './folder-details.component.html',
  styleUrls: ['./folder-details.component.css']
})
export class FolderDetailsComponent implements OnInit {

  selectedFolder = "Unsorted Forms";
  @Input('formCount') formCount: number;
  @Input('submissionCount') submissionCount: number;
  formDescription: String;
  folderName: String;
  
  constructor(private folderlistService: FolderListService,private httpClient: HttpClient) {
    if(this.formCount === undefined) {
      this.formCount = 0;
    }
    if(this.submissionCount === undefined) {
      this.submissionCount = 0;
    }
  }

  ngOnInit() {
    this.folderlistService.selectedFolderChange.subscribe(selectedFolder => {
      this.folderName = selectedFolder.name;
      this.selectedFolder = selectedFolder.name;
      console.log(selectedFolder);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      var bodyData = '{"folderId":"' + selectedFolder.id + '"}';
      this.httpClient.post<any>("/mvc/forms/GetFormCountByFolderId/",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
        console.log(res);
        this.formCount = res;
      });
    });
  }

}
