import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.css']
})
export class SettingsMenuComponent implements OnInit {

  navItems = [
    {id: 0, itemName: 'General'},
    {id: 1, itemName: 'Email & Actions'},
    {id: 2, itemName: 'Welcome & Submission Message'},
    {id: 3, itemName: 'Security'},
    {id: 4, itemName: 'User Access'},
    {id: 5, itemName: 'Approvals'},
    {id: 6, itemName: 'Integrations'},
    {id: 7, itemName: 'Plugins'},
    {id: 8, itemName: 'Advanced PDFs'},
  ];

  constructor() { }

  ngOnInit() {
  }

}
