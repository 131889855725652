import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pf-dashboard-grid-announcements',
  templateUrl: './pf-dashboard-grid-announcements.component.html',
  styleUrls: ['./pf-dashboard-grid-announcements.component.css'],
  host: {
      class: `class="fs--grid-1-2 fs--grid-md-1-1`
  }
})
export class PfDashboardGridAnnouncementsComponent implements OnInit {

  announcements = [
    {id: 0, title: "Test Announcement", details: "Test details"},
  ];

  constructor() { }

  ngOnInit() {
  }

}
