
import { Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FormsService {

    bodyData = '{"associatedGroup":"' + localStorage.getItem( 'guid' ) + '"}';
    headers = new HttpHeaders( {
        'Content-Type': 'application/json'
    } );

    constructor( private http: HttpClient, private ngZone: NgZone ) {

    }

    creatForm() {
        
    }

    getForms() {
        console.log('Getting forms');
        this.http.post<any>( "/mvc/forms/GetForms/", JSON.parse( this.bodyData ), { headers: this.headers } ).subscribe( ( res ) => {
            console.log( res );
            this.ngZone.run( () => {
              return res;
            } );
        } );
    }

    // getFormData(formId, dataKey) {
    //     let formObj;
    //     for(let i = 0; i < this.forms.length; i++) {
    //         if(this.forms[i].id == formId)
    //             formObj = this.forms[i];
    //     }
    //     switch(dataKey) {
    //         case 'name':
    //             return formObj.name;
    //         case 'dateCreated':
    //             return formObj.dateCreated;
    //         case 'fields':
    //             return formObj.fields;
    //         case 'submissions':
    //             return formObj.submissions;
    //     }
    // }

}