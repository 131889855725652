import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {

  @Input('items') items;
  listUnstyled = true;

  constructor() { }

  ngOnInit() {
  }

  formatItemDate(d) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    let month = d.substring(5, 7);
    if(month.substring(0, 1) == '0') {
      month = month.substring(1);
    }

    let day = d.substring(8, 10);
    let monthName = monthNames[month - 1];
    let year = d.substring(0, 4);
    let newDate = monthName + " " + day + ", " + year;
    return newDate;
  }

}
