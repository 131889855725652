import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pf-dashboard-grid-friends',
  templateUrl: './pf-dashboard-grid-friends.component.html',
  styleUrls: ['./pf-dashboard-grid-friends.component.css']
})
export class PfDashboardGridFriendsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
