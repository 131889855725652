import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FolderListService } from './../folder-list/folder-list.service';
import $ from 'jquery';
import 'bootstrap';

@Component( {
  selector: 'pf-contextmenu',
  templateUrl: './pf-contextmenu.component.html',
  styleUrls: [ './pf-contextmenu.component.css' ]
} )
export class PfContextMenuComponent implements OnInit {
  private wasInside = false;
  constructor( private folderListService: FolderListService ) { }

  @Input() x = 0;
  @Input() y = 0;
  @Input() folderObj;

  ngOnInit() {
  }

  addNewForm() {
    console.log( 'Add new Form' );
  }

  onClick( folder, event ) {
    console.log( "pf cm clicked!" );
    console.log( folder );
    event.preventDefault();
    event.stopPropagation();
  }

  openFolder( folder, event ) {

  }

  renameFolder( folder, event ) {
    this.folderListService.toModal( folder );
    $( '#contextMenuRenameFolderModal' ).modal( 'show' );
  }

  addFolder( folder, event ) {
    
  }

  deleteFolder( folder, event ) {
    this.folderListService.toModal( folder );
    $( '#contextMenuDeleteFolderModal' ).modal( 'show' );
  }

  addSubfolder( folder, event ) {
    this.folderListService.toModal( folder );
    $( '#contextMenuAddSubFolderModal' ).modal( 'show' );
  }

}
