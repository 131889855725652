import { Component, OnInit, Input, NgZone, HostListener } from '@angular/core';
import { FolderListService } from './folder-list/folder-list.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import $ from 'jquery';
import 'bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  folderObj;
  isLoggedIn;
  fullName;
  email;
  dateAdded;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  
  constructor(private httpClient: HttpClient, private folderlistService: FolderListService, private ngZone: NgZone) {
    if(localStorage.getItem('guid') != null) {
      this.isLoggedIn = true;
      this.fullName = localStorage.getItem('fullName');
      this.email = localStorage.getItem('email');
      this.dateAdded = localStorage.getItem('dateAdded');
    }else {
      this.isLoggedIn = false;
    }
  }

  ngOnInit() {
    this.folderlistService.modalFolder.subscribe(selectedFolder => {
      this.folderObj = selectedFolder;
      //this.folderName = selectedFolder.name;
      //this.selectedFolder = selectedFolder.name;
    });
    this.folderlistService.contextData.subscribe(data => { 
      this.contextmenu = data.isVisible;
      this.contextmenuX = data.xj;
      this.contextmenuY = data.yj;
      this.folderObj = data.folderObj;
    });
  }

  addSubFolder(folderName, folder) {
    var bodyData = '{"associatedGroup":"' + localStorage.getItem('guid') + '", "folderId":"' + folder.id + '","folderName":"' + folderName.value + '"}';
    this.httpClient.post<any>("/mvc/folders/AddSubFolder/",JSON.parse(bodyData), {headers: this.headers}).subscribe((res)=>{
      $("#contextMenuAddSubFolderModal").modal("hide");
      this.folderlistService.foldersReload();
    });
  }

  renameFolder(folderName, folder) {
    var bodyData = '{"associatedGroup":"' + localStorage.getItem('guid') + '", "folderId":"' + folder.id + '","folderName":"' + folderName.value + '"}';
    this.httpClient.post<any>("/mvc/folders/RenameFolder/",JSON.parse(bodyData), {headers: this.headers}).subscribe((res)=>{
      $("#contextMenuRenameFolderModal").modal("hide");
      this.folderlistService.foldersReload();    
    });
  }

  deleteFolder(folder) {
    var bodyData = '{"associatedGroup":"' + localStorage.getItem('guid') + '", "folderId":"' + folder.id + '"}';
    this.httpClient.post<any>("/mvc/folders/DeleteFolder/",JSON.parse(bodyData), {headers: this.headers}).subscribe((res)=>{
      $("#contextMenuDeleteFolderModal").modal("hide");
      this.folderlistService.foldersReload();    
    });
  }

}
