import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import $ from 'jquery';

@Component({
  selector: 'pf-dashboard-grid-forms',
  templateUrl: './pf-dashboard-grid-forms.component.html',
  styleUrls: ['./pf-dashboard-grid-forms.component.css']
})
export class PfDashboardGridFormsComponent implements OnInit {

  @Input('forms') forms;
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
  }

  createForm(name, desc, url, folder, rFolderObj) {
    var bodyData = '{"associatedGroup":"' + localStorage.getItem('guid') + '", "userIdCreated":"' + localStorage.getItem('userId') + '", "folderId":"' + rFolderObj.id + '","formName":"' + name.value + '"}';
    this.httpClient.post<any>("/mvc/forms/CreateForm/",JSON.parse(bodyData), {headers: this.headers}).subscribe((res)=>{
      $("#createFormModal").modal("hide");
      //this.folderlistService.toggle(rFolderObj);
      //this.reloadFolderList();
    });
  }

}
