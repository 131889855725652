import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  selectedTab;

  constructor() { }

  ngOnInit() {
  }

  saveNewPassword(newPassword) {

  }

}
