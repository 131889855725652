import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'submissions-search',
  templateUrl: './submissions-search.component.html',
  styleUrls: ['./submissions-search.component.css']
})
export class SubmissionsSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  newSearch() {
    console.log('new search');
  }

}
