import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'search-results-header',
  templateUrl: './search-results-header.component.html',
  styleUrls: ['./search-results-header.component.css']
})
export class SearchResultsHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
