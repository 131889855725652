import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pf-badge',
  styleUrls: ['./pf-badge.component.css'],
  template: `{{ badgeNumber }} {{ badgeType }}`
})
export class PfBadgeComponent implements OnInit {
  @Input('badgeType') badgeType: string;
  @Input('badgeNumber') badgeNumber;

  constructor() { }

  ngOnInit() {
    //console.log(this.totalSubmissions);
  }

}
