import { Router } from '@angular/router';
import { Injectable, Output, EventEmitter, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { PfNavbarComponent} from './../app/pf-navbar/pf-navbar.component';
import { User } from '../models/user.model';
import { Enums } from '../../src/app/enums/enums';
 
@Injectable({ providedIn: 'root' })
export class UserService {
    private thisUser = new User();
    
    private guid = '{"associatedGroup":"' + localStorage.getItem( 'guid' ) + '"}';
    private userId = localStorage.getItem( 'userId' ) ;
    private headers = new HttpHeaders( {
        'Content-Type': 'application/json'
    } );

    @Output() onUserRegister: EventEmitter<any> = new EventEmitter();
    @Output() onUserLogin: EventEmitter<any> = new EventEmitter();
    @Output() onUserLogout: EventEmitter<any> = new EventEmitter();

    constructor( private pfNavbarComponent: PfNavbarComponent ,private http: HttpClient, private ngZone: NgZone, private router: Router ) { }
    
    register( user: User ) {
        return this.http.post(`auth/register`, user);
    }

    // Returns the Global Unique ID for the current logged in user
    getGuid() {
        return this.guid;
    }

    // Returns the role of the current logged in user
    getUserRole(): Enums.UserRoles {
        return Enums.UserRoles.ADMIN;
    }

    // Returns the role of a user from their id (not guid)
    getUserRoleById(): Enums.UserRoles {
        let role: number = 0;        
        let bodyData = '{"userId":"' + this.userId.toString() + '"}';
        this.http.post<any>( "/mvc/accounts/GetRole/", JSON.parse( bodyData ), { headers: this.headers } ).subscribe( ( response ) => {
            console.log('we in');
            console.log( "Role num:" + response[0].Role );
            this.ngZone.run( () => {
              role = response[0].Role;
            } );
        } );
        console.log( `Role: ${ role }` );
        return role;
        //return Enums.UserRoles.ADMIN;
    }

    // Returns true if this user has a role of admin (2000)
    isUserAdmin(): boolean {
        return ( this.getUserRoleById() == Enums.UserRoles.ADMIN ? true : false );
    }

    // Registers a new user
    registerUser( form ) {
        console.log(form.form.value);
        let bodyData = "{'emailAddress':'" +  form.value.email + "'}";
        let emailMatch: number;
        this.http.post<any>( "/mvc/accounts/GetCountEmail/", bodyData, { headers: this.headers } ).subscribe( ( response ) => {
            emailMatch = response.count;
            console.log(emailMatch);

            if ( emailMatch > 0 ) {
                alert( "An account already exists with that email address." );
            } else {
                this.http.post<any>( "/mvc/accounts/register/", form.form.value, { headers: this.headers } ).subscribe( ( response ) => {
                    if ( response.value === "Failure" ) {
                        console.log( "Account could not be created." );
                    } else {
                        this.onRegister( response );
                        let redirectWindow: string = '/login';
                        window.location.href = redirectWindow;
                    }
                } );
            }
        } );
        
    }

    // Attempts to log a user in by checking email and hashed password against the database
    checkLogin( form ): string | {} {
        return this.http.post<any>( "/mvc/accounts/login/", form.form.value, { headers: this.headers } ).subscribe( ( response ) => {            
            //this.loginUser( response );
            if ( response.fullName != "" )
            {
                this.loginUser( response );
                this.pfNavbarComponent.isLoggedIn = true;
            }
            
            //this.router.navigate( [ '/forms' ] );
            return response;
        } );
    }

    // Logs the user in
    loginUser( response ) {
        let redirectWindow: string = '/forms';
        this.onLogin( response );
        //this.router.navigate( [ redirectWindow ] );
        window.location.href = redirectWindow;
    }

    // Runs when a user is successfully registered
    private onRegister( userInfo ) {
        localStorage.setItem( 'guid', userInfo.value );
        localStorage.setItem( 'fullName', userInfo.fullName );
        localStorage.setItem( 'email', userInfo.email );
        localStorage.setItem( 'dateAdded', userInfo.dateAdded );
        this.onUserRegister.emit();
    }

    // Runs when a user successfully logs in
    private onLogin( userInfo ) {
        localStorage.setItem( 'guid', userInfo.value );
        localStorage.setItem( 'fullName', userInfo.fullName );
        localStorage.setItem( 'email', userInfo.email );
        localStorage.setItem( 'dateAdded', userInfo.dateAdded );
        localStorage.setItem( 'userId', userInfo.userId );
        this.onUserLogin.emit();
    }

    // Runs when a user successfully logs out
    private onLogout() {
        this.onUserLogout.emit();
    }
    
}