import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'submission-edit-item',
  templateUrl: './submission-edit-item.component.html',
  styleUrls: ['./submission-edit-item.component.css']
})
export class SubmissionEditItemComponent implements OnInit {

  @Input() fieldName: string;
  @Input() fieldValue;
  @Input() fieldType;
  isChecked = false;

  constructor() {}

  ngOnInit() {
    /*if(this.fieldType.type == 'checkbox-group' && this.fieldValue == this.fieldType.options[this.getValueIndex()]) {
      this.isChecked == true;
      let input = document.getElementsByClassName('checkbox-selector')[this.getValueIndex()];
      try {
        let attr = document.createAttribute("checked");
        console.log('input');
        console.log(input);
        input.setAttributeNode(attr);
      } catch(e) {
        console.log(e);
      }
    }*/
  }

  camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  getValueIndex() {
    return this.fieldType.options.indexOf(this.fieldValue);
  }

  checkboxHandler(index) {
    if(this.getValueIndex() == index) return true
  }

  getMonth(s) {
    console.log("month");
    console.log(s);
    var formattedDate = new Date(s);
    console.log(formattedDate);
    var d = formattedDate.getDate();
    var mon =  ( '0' + (formattedDate.getUTCMonth()+1) ).slice( -2 );
    console.log(mon);
    return mon;
  }
  getDay(s) {
    console.log(s);
    console.log("day");
    var formattedDate = new Date(s);
    var d = formattedDate.getDate();
    var day =  ( '0' + (formattedDate.getUTCDate()) ).slice( -2 );
    console.log(day);
    return day;
  }
  getYear(s) {
    console.log(s);
    var formattedDate = new Date(s + "Z");
    var d = formattedDate.getDate();
    //var day =  ( '0' + (formattedDate.getFullYear()+1) ).slice( -2 );
    //console.log(day);
    return formattedDate.getUTCFullYear();
  }

  getOptionIndex(optionVal) {
    //console.log(optionVal);
    //console.log(this.fieldType.options);
    //console.log(this.fieldType.options.indexOf(optionVal));

    //console.log(this.fieldValue);
    //console.log(this.fieldValue.indexOf(optionVal));
    //return this.fieldValue.indexOf(optionVal);
    if(this.fieldValue.indexOf(optionVal) >= 0)
    {
      return -1;
    }else{
      return 0;
    }
    //return this.fieldType.options.indexOf(optionVal);
  }

}
