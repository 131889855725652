export namespace Enums {
    // All possible roles a user can have
    export enum UserRoles {
        MEMBER = 0,     // Free user
        PRO,            // First-tier paid user
        PREMIUM,        // Second-tier paid user
        DIAMOND,        // Third-tier paid user
        STAFF,          // Staff member
        ADMIN = 2000    // Top-level admin
    }

    // Default static folders that every user inherits
    export enum DefaultFolders {
        UNCATEGORIZED = 0,
        ARCHIVED,
        DELETED
    }
}