import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "pf-expand",
  templateUrl: "./pf-expand.component.html",
  styleUrls: ["./pf-expand.component.css"],
})
export class PfExpandComponent implements OnInit {
  @Output() emitIsExpanded = new EventEmitter<boolean>();

  isExpanded = true;

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.isExpanded = !this.isExpanded;
    this.emitIsExpanded.emit(this.isExpanded);
  }
}
