import { Component, OnInit, Input } from '@angular/core';
import { NavbarService } from './../../services/navbar.service';

@Component({
  selector: 'pf-dashboard-grid',
  templateUrl: './pf-dashboard-grid.component.html',
  styleUrls: ['./pf-dashboard-grid.component.css']
})
export class PfDashboardGridComponent implements OnInit {

  @Input('forms') forms;

  constructor(public navbarService: NavbarService) { }

  ngOnInit() {
    //this.navbarService.setTab(1);
  }

}
