import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "pf-icon",
  templateUrl: "./pf-icon.component.html",
  styleUrls: ["./pf-icon.component.css"],
})
export class PfIconComponent implements OnInit {
  @Input("icon") icon: string;
  @Input("color") color: string;

  constructor() {}

  ngOnInit() {}

  changeColor(): any {
    return { color: this.color };
  }
}
