import { Component, OnInit, Input, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'submission-view-header',
  templateUrl: './submission-view-header.component.html',
  styleUrls: ['./submission-view-header.component.css']
})
export class SubmissionViewHeaderComponent implements OnInit {

  itemName: string;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient, private ngZone: NgZone) { }

  ngOnInit() {
    this.route.paramMap
      .subscribe(params => {
        let itemId = +params.get('itemId');
        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
        });
        var bodyData = '{"formId":"' + itemId + '"}';
        this.httpClient.post<any>("/mvc/Forms/GetFormObjectById",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
          console.log('SUBMISSIONS NAME BY ID BOIIII');
          console.log(res);
          this.ngZone.run(() => {
            console.log('header ng zone');
            console.log(res[0]);
            this.itemName = res[0].name;
            console.log(this.itemName);
          });
        });
      });
  }

}
