import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from './../../services/navbar.service';

import $ from 'jquery';

@Component({
  selector: 'pf-navbar',
  templateUrl: './pf-navbar.component.html',
  styleUrls: ['./pf-navbar.component.css']
})
export class PfNavbarComponent implements OnInit {

  @Input('isLoggedIn') isLoggedIn;
  theme = 0;
  fullName;
  email;
  dateAdded;

  constructor(private route: ActivatedRoute, public navbarService: NavbarService, private router:Router) {
    
  }

  ngOnInit() {


    if(localStorage.getItem('guid') != null) {
      this.isLoggedIn = true;
      this.fullName = localStorage.getItem('fullName');
      this.email = localStorage.getItem('email');
      this.dateAdded = localStorage.getItem('dateAdded');
    }else {
      this.isLoggedIn = false;
    }
    this.navbarService.themeOutput.subscribe(theme => {
      let themeName = "";
      this.theme = theme;
      console.log('theme updated');
      console.log(this.theme);
      if(this.theme == 1) {
        themeName = "dark";
      }else {
        themeName = "light";
      }
      $('body').attr('data-theme', themeName);
    });
  }

  toggleProfileDropDown(name) {
    let id = "header__dropdown-" + name;
    var e = document.getElementById(id);
    if(e.classList.contains("ng-hide")){
      e.classList.remove("ng-hide");
    }else{
      e.classList.add("ng-hide");
    }
  }

  logOut() {
    this.isLoggedIn = false;
    console.log('is logged in: ' + this.isLoggedIn);
    localStorage.clear();
    window.location.href = 'login';
  }

}
