import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsService } from '../../services/forms.service';
import { SubmissionsService } from '../../services/submissions.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Location} from '@angular/common'; 
import * as $ from 'jquery';

@Component({
  selector: 'submission-view',
  templateUrl: './submission-view.component.html',
  styleUrls: ['./submission-view.component.css']
})
export class SubmissionViewComponent implements OnInit {

  //formsService = new FormsService();

  viewMode = 'view';
  itemName: string;
  submissionObject;
  submissionId;
  fields;
  webhooks = [];
  formObject;

  constructor(private location: Location,private router: Router,private route: ActivatedRoute, public submissionsService: SubmissionsService, private httpClient: HttpClient, private ngZone: NgZone) { }


  initComponentState(){

    console.log("test new init");
    this.route.paramMap
      .subscribe(params => {
        let itemId = +params.get('itemId');
        this.submissionId = +params.get('submissionId');
        this.viewMode = params.get('viewMode');

        //this.itemName = this.formsService.getFormData(itemId, 'name');
        let retVal;
        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
        });
        var bodyData = '{"formId":"' + itemId + '","submissionId":"' + this.submissionId + '"}';
        this.httpClient.post<any>("/mvc/Forms/GetFormSubmissionsById",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
          this.ngZone.run( () => {
            this.submissionObject = retVal;
            this.fields = JSON.parse(res[0]['fields']);
            //this.totalSubmissions = this.submissions.length;
          });
        });

        var formRequestData = '{"formId":"' + itemId + '","associatedGroup":"' + localStorage.getItem("guid") + '"}';
        this.httpClient.post<any>("/mvc/Forms/GetFormObjectById",JSON.parse(formRequestData), {headers: headers}).subscribe((res)=>{
          this.ngZone.run( () => {
            this.webhooks = JSON.parse(res[0]['webHooks']);
            //this.totalSubmissions = this.submissions.length;
          });
        });
      });


  }

  ngOnInit() {
    this.initComponentState();
  }
  runHook(url)
  {
    console.log("run");
    var sData = 
    {
      fieldData: this.fields,
      sId: this.submissionId 
    }
    var s2Data = JSON.stringify(sData);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let bodyData = '{"content": ' + JSON.stringify(s2Data) + '}';
    this.httpClient.post<any>(url,JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
      this.ngZone.run( () => {
        
      });
    });
    
  }

  saveChanges() {
    var nFields = this.fields;
    let fields = [];
    let nameArray = [];
    let valueArray = [];
    $('.individual-submission-field-label').each(function() {
      let name = $(this).html();
      nameArray.push(name);
    });
    $(".edit-form-data").each(function() {
      var value;
      console.log($(this).get(0).tagName);
      if($(this).get(0).tagName == 'INPUT'){
        console.log('test');
        value = $(this).val();
        if(value==null)
        {
          value="";
        }
      }else{
        console.log("Not Input");
        if($(this).hasClass('radio-group'))
        {
          //console.log("radio-group");
          //console.log($(this).find("input.ng-touched"))
          value = $(this).find("input:checked").attr("ng-reflect-value");
        } else if($(this).hasClass('select-form')){
          //console.log("select-form");
          value = $(this).val();
        } else if($(this).hasClass('date')){
          console.log("date"); 
          //value = $(this).val();
          var month = $(this).find(".month").val();
          var day = $(this).find(".day").val();
          var year = $(this).find(".year").val();
          value = year + "-" + month + "-" + day;
          if(year == "" || month == "" || day == "")
          {
            value = "";
          }
        } else if($(this).hasClass('checkbox-group')) {
          value = [];
          $(this).find("input:checked").each(function(){
            //console.log("Checkbox");
            //console.log($(this).val());
            //value = $(this).val();
            
            value.push($(this).val());
            console.log(value);
          });
        }

      }
      if(value==null)
      {
        value="";
      }
      valueArray.push(value);
    });
    $.each(nameArray, function(i) {
      let field = {
        name: '',
        value: '',
      };
      let name = nameArray[i];
      let value = valueArray[i];
      field.name = name;
      field.value = value;
      fields.push(field);
      //console.log(nFields);
      nFields[i].value = value;
      //this.fields[i].value = value;
    });
    console.log('New Object');
    console.log(fields);
    console.log('Old Object');
    console.log(this.fields);
    console.log(this.fields[0].value);
    var sData = 
    {
      fieldData: nFields,
      sId: this.submissionId 
    }
    var s2Data = JSON.stringify(sData);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let bodyData = '{"content": ' + JSON.stringify(s2Data) + '}';
    /*this.httpClient.post<any>("/mvc/forms/ResubmitToEmr/",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
      this.ngZone.run( () => {
        
      });
    });*/
    
    
    //this.router.navigate(['/about']);
    this.httpClient.post<Array<Object>>("https://portal.exphs.com/webservice%20center/getemrdata.asmx/UpdateFormSubmission" , JSON.stringify(sData)).subscribe((res)=>{
        console.log(res);
        this.route.paramMap
        .subscribe(params => {
          let itemId = +params.get('itemId');
          this.submissionId = +params.get('submissionId');
          ///forms/submissions/36/edit/3069
          this.ngZone.run( () => {
            this.viewMode = 'view';
            //this.location.replaceState("/forms/submissions/" + itemId + "/view/" +this.submissionId);
            //this.initComponentState();
          });
          
        });
        
    });

    /*
    $.ajax({
      type: "POST",
      url: "https://portal.exphs.com/webservice%20center/getemrdata.asmx/UpdateFormSubmission",
      data: s2Data,
      dataType: "json",
      contentType: "application/json",
      success: function(){
        console.log("success");
      },
    });*/




    //----angular way----
    /*let elements = this.elem.nativeElement.querySelectorAll('.option_input');
    elements.forEach(element => {
     if(element.checked){
        element.checked = false
     }*/


    // let hasChanges = false;
    // for (let prop in f) {
    //   if (this.isDifferent(f, prop)) { hasChanges = true; }
    // }
    // // If no changes, cancel form submition
    // if (!hasChanges) { return; }
  }
  initEdit()
  {
    this.ngZone.run( () => {
      this.viewMode = 'edit';
      //this.location.replaceState("/forms/submissions/" + itemId + "/view/" +this.submissionId);
      //this.initComponentState();
    });
  }

  //Creates a reference of your initial value
  createReference(obj: any) {
    this.submissionObject = Object.assign({}, obj);
    console.log("createReference log");
    console.log(obj);
  }

  // Returns true if the user has changed the value in the form
  isDifferent(obj: any, prop: string) {
    console.log("isDifferent log");
    console.log(obj);
    console.log(prop);
    return this.submissionObject[prop] !== obj[prop];
  }

  createEmptyWebhook() {
    this.webhooks.push( { name: "", url: "" } );
  }

  deleteWebhook( index ) {
    this.webhooks.splice( index, 1 );
  }

}
