import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dashboard-checkbox-input',
  templateUrl: './dashboard-checkbox-input.component.html',
  styleUrls: ['./dashboard-checkbox-input.component.css']
})
export class DashboardCheckboxInputComponent implements OnInit {
  isChecked: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  onCheckboxClicked() {
    this.isChecked = !this.isChecked;
  }

}
