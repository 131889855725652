import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pf-pagination2',
  templateUrl: './pf-pagination2.component.html',
  styleUrls: ['./pf-pagination2.component.css']
})
export class PfPagination2Component implements OnInit {

  @Input() numItems: number;

  @Output('pageChange') pageChange: EventEmitter<number> = new EventEmitter();
  @Output('rowsPerPageChange') rowsPerPageChange: EventEmitter<number> = new EventEmitter();

  startingPage: number = 1;
  selectedPage: number;
  numDisplayedPages: number = 5;
  numEdgePages: number = 2;
  displayedPages: number[] = [];
  numPages: number;
  rowsPerPage: number = 25;

  constructor() {
  }

  ngOnInit() {
    this.generatePager();
  }

  generatePager() {
    this.displayedPages = [];
    if (!this.selectedPage) {
      this.selectedPage = this.startingPage;
    }
    this.numPages = Math.ceil( this.numItems / this.rowsPerPage );
    for ( var i = 0; i < this.numDisplayedPages; i++ ) {
      var pageNum = this.selectedPage - this.numEdgePages + i;
      if ( pageNum > 0 && pageNum <= this.numPages ) {
        this.displayedPages.push( pageNum );
      }
    }
    if ( this.displayedPages.length < this.numDisplayedPages ) {
      var numPagesNeeded = this.numDisplayedPages - this.displayedPages.length;
      if ( this.selectedPage - this.numEdgePages < 1 ) {
        for ( var i = 1; i <= numPagesNeeded; i++ ) {
          var pageNumUnder = this.displayedPages[this.displayedPages.length - 1] + 1;
          this.displayedPages.push( pageNumUnder );
        }
      } else if ( this.selectedPage + this.numEdgePages > this.numPages ) {
        var min = 0;
        for ( var i = 1; i <= numPagesNeeded; i++ ) {
          var pageNumOver = this.displayedPages [this.displayedPages.length - 1];
          if( pageNumOver != this.displayedPages[this.displayedPages.length - 1] ) {
            this.displayedPages.push( pageNumOver );
          } else {
            if( min == 0 ) {
              min = this.displayedPages[0] - 1;
            } else {
              min = min - 1;
            }
            this.displayedPages.push( min );
          }
        }
      }
    }
    this.displayedPages.sort( ( a, b ) => a - b );
  }

  onPageClick(pageNum: number) {
    if ( this.selectedPage != pageNum ) {
      this.selectedPage = pageNum;
      this.generatePager();
      this.pageChange.emit( this.selectedPage );
    }
  }

  pagePrevious() {
    if ( this.selectedPage != 1 ) {
      this.selectedPage = this.displayedPages[this.displayedPages.indexOf( this.selectedPage ) - 1];
      this.generatePager();
      this.pageChange.emit( this.selectedPage );
    }
  }

  pageNext() {
    if ( this.selectedPage != this.numPages ) {
      this.selectedPage = this.displayedPages[this.displayedPages.indexOf( this.selectedPage ) + 1];
      this.generatePager();
      this.pageChange.emit( this.selectedPage );
    }
  }

  jumpToFirst() {
    if ( this.selectedPage != 1 ) {
      this.selectedPage = 1;
      this.generatePager();
      this.pageChange.emit( this.selectedPage );
    }
  }

  jumpToLast() {
    if ( this.selectedPage != this.numPages ) {
      this.selectedPage = this.numPages;
      this.generatePager();
      this.pageChange.emit(this.selectedPage);
    }
  }

  onPageSizeChange( size ) {
    this.rowsPerPage = size;
    this.rowsPerPageChange.emit( this.rowsPerPage );
    this.generatePager();
    this.pageChange.emit(this.selectedPage);
  }

}
