import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class ItemCheckboxesService {

  selectedIds = [];

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() allToggle: EventEmitter<any> = new EventEmitter();

  toggle(selectedIds) {
    this.selectedIds = selectedIds;
    this.emitIds();
  }

  emitIds() {
    this.change.emit(this.selectedIds);
  }

  mainCheckToggle(checkboxStatus) {
    this.allToggle.emit(checkboxStatus);
  }

  addItemId(item) {
    this.selectedIds.push(item.id);
    this.emitIds();
  }

  removeItemId(item) {
    let i = this.selectedIds.indexOf(item.id);
    if (i > -1) {
      this.selectedIds.splice(i, 1);
    }
    this.emitIds();
  }

}
