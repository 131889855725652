import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pf-builder-edit-btn',
  templateUrl: './pf-builder-edit-btn.component.html',
  styleUrls: ['./pf-builder-edit-btn.component.css']
})
export class PfBuilderEditBtnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
