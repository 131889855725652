import { UserService } from './../../services/user.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'pf-sign-up',
  templateUrl: './proforms-sign-up.component.html',
  styleUrls: ['./proforms-sign-up.component.css']
})
export class ProformsSignUpComponent implements OnInit {

  constructor( private httpClient: HttpClient, private user: UserService ) { }

  ngOnInit() {
  }

  submit( f ) {
    this.user.registerUser( f );
  }

}
