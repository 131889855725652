import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  searchValue;

  @Output() change: EventEmitter<string> = new EventEmitter();
  @Output() formSearch: EventEmitter<any> = new EventEmitter();

  constructor() { }

  search(searchValue) {
    this.searchValue = searchValue;
    this.formSearch.emit(this.searchValue);
    console.log(this.searchValue);
  }

}
