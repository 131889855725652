import { ItemCheckboxesService } from './../services/item-checkboxes.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsDashboardComponent } from './forms-dashboard/forms-dashboard.component';
import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
import { DashboardCheckboxComponent } from './dashboard-checkbox/dashboard-checkbox.component';
import { DashboardCheckboxInputComponent } from './dashboard-checkbox-input/dashboard-checkbox-input.component';
import { DashboardCheckboxLabelComponent } from './dashboard-checkbox-label/dashboard-checkbox-label.component';
import { FormsService } from '../services/forms.service';
import { PaginationService } from './../services/pagination.service';
import { NavbarService } from './../services/navbar.service';
import { DashboardCheckboxToggleComponent } from './dashboard-checkbox-toggle/dashboard-checkbox-toggle.component';
import { PfBadgeComponent } from './pf-badge/pf-badge.component';
import { PfActionBarComponent as PfActionBarComponent } from './pf-action-bar/pf-action-bar.component';
import { FolderListComponent } from './folder-list/folder-list.component';
import { FolderNavComponent } from './folder-nav/folder-nav.component';
import { FolderDetailsComponent } from './folder-details/folder-details.component';
import { DashboardBtnComponent } from './dashboard-btn/dashboard-btn.component';
import { DashboardSearchComponent } from './dashboard-search/dashboard-search.component';
import { DashboardFolderComponent } from './dashboard-folder/dashboard-folder.component';
import { SubmissionsComponent } from './submissions/submissions.component';
import { FormNotFoundComponent } from './form-not-found/form-not-found.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { SubmissionsEmptyComponent } from './submissions-empty/submissions-empty.component';
import { SubmissionsService } from 'src/services/submissions.service';
import { SubmissionViewComponent } from './submission-view/submission-view.component';
import { SubmissionViewHeaderComponent } from './submission-view-header/submission-view-header.component';
import { SubmissionViewPageHeaderComponent } from './submission-view-page-header/submission-view-page-header.component';
import { SubmissionViewItemComponent } from './submission-view-item/submission-view-item.component';
import { SubmissionEditItemComponent } from './submission-edit-item/submission-edit-item.component';
import { ProformsHomeComponent } from './proforms-home/proforms-home.component';
import { ProformsSignUpComponent } from './proforms-sign-up/proforms-sign-up.component';
import { ProformsSignInComponent } from './proforms-sign-in/proforms-sign-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from '../helpers/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { FormSettingsComponent } from './form-settings/form-settings.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { NavItemComponent } from './nav-item/nav-item.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { PfFormElementComponent } from './pf-form-element/pf-form-element.component';
import { PfIconComponent } from './pf-icon/pf-icon.component';
import { FolderListService } from './folder-list/folder-list.service';
import { PfNavbarComponent } from './pf-navbar/pf-navbar.component';
import { PfFormBuilderComponent } from './pf-form-builder/pf-form-builder.component';
import { PfContextMenuComponent } from './pf-contextmenu/pf-contextmenu.component';
import { PfDashboardComponent } from './pf-dashboard/pf-dashboard.component';
import { PfDashboardGridComponent } from './pf-dashboard-grid/pf-dashboard-grid.component';
import { PfDashboardGridFormsComponent } from './pf-dashboard-grid-forms/pf-dashboard-grid-forms.component';
import { PfDashboardGridFriendsComponent } from './pf-dashboard-grid-friends/pf-dashboard-grid-friends.component';
import { PfDashboardGridThemesComponent } from './pf-dashboard-grid-themes/pf-dashboard-grid-themes.component';
import { PfDashboardGridAnnouncementsComponent } from './pf-dashboard-grid-announcements/pf-dashboard-grid-announcements.component';
import { ShareComponent } from './share/share.component';
import { PfInputAddonComponent } from './pf-input-addon/pf-input-addon.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { AllAnnouncementsComponent } from './all-announcements/all-announcements.component';
import { CreateFormModalComponent } from './create-form-modal/create-form-modal.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchResultsHeaderComponent } from './search-results-header/search-results-header.component';
import { PfBuilderEditBtnComponent } from './pf-builder-edit-btn/pf-builder-edit-btn.component';
import { UsersAndGroupsComponent } from './users-and-groups/users-and-groups.component';
import { BillingComponent } from './billing/billing.component';
import { UrlsComponent } from './urls/urls.component';
import { SmtpSettingsComponent } from './smtp-settings/smtp-settings.component';
import { UploadManagerComponent } from './upload-manager/upload-manager.component';
import { AccountSecurityComponent } from './account-security/account-security.component';
import { AccountUsageComponent } from './account-usage/account-usage.component';
import { ApiComponent } from './api/api.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ProformsFormComponent } from './proforms-form/proforms-form.component';
import { SubmissionsFilterComponent } from './submissions-filter/submissions-filter.component';
import { SubmissionsSearchComponent } from './submissions-search/submissions-search.component';
import { SubmissionsImportDataComponent } from './submissions-import-data/submissions-import-data.component';
import { SubmissionsShareComponent } from './submissions-share/submissions-share.component';
import { PfInputBtnComponent } from './pf-input-btn/pf-input-btn.component';
import { PfDropdownComponent } from './pf-dropdown/pf-dropdown.component';
import { SubmissionsDownloadAllComponent } from './submissions-download-all/submissions-download-all.component';
import { SubmissionsDeleteAllComponent } from './submissions-delete-all/submissions-delete-all.component';
import { PfPaginationComponent } from './pf-pagination/pf-pagination.component';
import { ClickElsewhereDirective } from './directives/click-elsewhere.directive';
import { DateStandardPipe } from './pipes/date-standard.pipe';
import { PfPagination2Component } from './pf-pagination2/pf-pagination2.component';
import { PfExpandComponent } from './pf-expand/pf-expand.component';
import { PfTooltipComponent } from './pf-tooltip/pf-tooltip.component';

@NgModule({
  declarations: [
    AppComponent,
    FormsDashboardComponent,
    DashboardItemComponent,
    DashboardCheckboxComponent,
    DashboardCheckboxInputComponent,
    DashboardCheckboxLabelComponent,
    DashboardCheckboxToggleComponent,
    PfBadgeComponent,
    PfActionBarComponent,
    FolderListComponent,
    FolderNavComponent,
    FolderDetailsComponent,
    DashboardBtnComponent,
    DashboardSearchComponent,
    DashboardFolderComponent,
    SubmissionsComponent,
    FormNotFoundComponent,
    DashboardHomeComponent,
    SubmissionsEmptyComponent,
    SubmissionViewComponent,
    SubmissionViewHeaderComponent,
    SubmissionViewPageHeaderComponent,
    SubmissionViewItemComponent,
    SubmissionEditItemComponent,
    ProformsHomeComponent,
    ProformsSignUpComponent,
    ProformsSignInComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    FormSettingsComponent,
    SettingsMenuComponent,
    NavItemComponent,
    GeneralSettingsComponent,
    PfFormElementComponent,
    PfIconComponent,
    PfNavbarComponent,
    PfFormBuilderComponent,
    PfContextMenuComponent,
    PfDashboardComponent,
    PfDashboardGridComponent,
    PfDashboardGridFormsComponent,
    PfDashboardGridFriendsComponent,
    PfDashboardGridThemesComponent,
    PfDashboardGridAnnouncementsComponent,
    ShareComponent,
    PfInputAddonComponent,
    AnnouncementsComponent,
    AllAnnouncementsComponent,
    CreateFormModalComponent,
    SearchResultsComponent,
    SearchResultsHeaderComponent,
    PfBuilderEditBtnComponent,
    UsersAndGroupsComponent,
    BillingComponent,
    UrlsComponent,
    SmtpSettingsComponent,
    UploadManagerComponent,
    AccountSecurityComponent,
    AccountUsageComponent,
    ApiComponent,
    ReferralsComponent,
    SpinnerComponent,
    ProformsFormComponent,
    SubmissionsFilterComponent,
    SubmissionsSearchComponent,
    SubmissionsImportDataComponent,
    SubmissionsShareComponent,
    PfInputBtnComponent,
    PfDropdownComponent,
    SubmissionsDownloadAllComponent,
    SubmissionsDeleteAllComponent,
    PfPaginationComponent,
    ClickElsewhereDirective,
    DateStandardPipe,
    PfPagination2Component,
    PfExpandComponent,
    PfTooltipComponent,
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'forms'
      },
      {
        path: 'home',
        component: ProformsHomeComponent
      },
      {
        path: 'signup',
        component: ProformsSignUpComponent
      },
      {
        path: 'login',
        component: ProformsSignInComponent
      },
      {
        path: 'forgotpassword',
        component: ForgotPasswordComponent
      },
      {
        path: 'settings/profile',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/users',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/billing',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/urls',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/smtp-settings',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/upload-manager',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/account/security',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/account/usage',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/api',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings/referrals',
        component: ProfileComponent, canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: PfDashboardComponent, canActivate: [AuthGuard]
      },
      {
        path: 'announcements',
        component: AllAnnouncementsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'announcements/:announcementId',
        component: AnnouncementsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'forms',
        component: DashboardHomeComponent, canActivate: [AuthGuard]
      },
      {
        path: 'forms/build/:itemId',
        component: PfFormBuilderComponent, canActivate: [AuthGuard]
      },
      {
        path: 'forms/settings/:itemId',
        component: FormSettingsComponent, canActivate: [AuthGuard]
      },
      { 
        path: 'forms/submissions/:itemId',
        component: SubmissionsComponent, canActivate: [AuthGuard]
      },
      { 
        path: 'forms/submissions/:itemId/:viewMode/:submissionId',
        component: SubmissionViewComponent, canActivate: [AuthGuard]
      },
      {
        path: 'forms/share/:itemId',
        component: ShareComponent, canActivate: [AuthGuard]
      },
      {
        path: 'form',
        component: ProformsFormComponent, canActivate: [AuthGuard]
      },
      { 
        path: '**',
        pathMatch: 'full',
        redirectTo: 'forms'
      },
    ])
  ],
  providers: [
    HttpClientModule,
    FormsService,
    SubmissionsService,
    FolderListService,
    ItemCheckboxesService,
    PaginationService,
    NavbarService,
    PfNavbarComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
