import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-security',
  templateUrl: './account-security.component.html',
  styleUrls: ['./account-security.component.css']
})
export class AccountSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
