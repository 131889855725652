import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { ItemCheckboxesService } from '../../services/item-checkboxes.service';


@Component({
  selector: 'dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.css']
})
export class DashboardItemComponent implements OnInit {
  @Input() itemId;
  totalSubmissions;
  static count : 0;
  allChecked;
  
  @Input('items') nItems: Array<Object>;

  constructor(private itemCheckboxesService: ItemCheckboxesService) {
    //let formsService = new FormsService();
    this.itemCheckboxesService.allToggle.subscribe(status => {
      this.allChecked = status;
    });
  }

  ngOnInit() {
    this.totalSubmissions = this.itemId.submissions;
  }

  onCheckboxChange(item, event) {
    if(event.target.checked) {
      this.itemCheckboxesService.addItemId(item);
    }else {
      this.itemCheckboxesService.removeItemId(item);
    }
  }

}
