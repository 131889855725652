import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pf-tooltip',
  templateUrl: './pf-tooltip.component.html',
  styleUrls: ['./pf-tooltip.component.css']
})
export class PfTooltipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
