import { SpinnerComponent } from './../spinner/spinner.component';
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '../../services/user.service';
import { SubmissionsService } from '../../services/submissions.service';
import { PaginationService } from '../../services/pagination.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import $ from 'jquery';

@Component( {
  selector: 'submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.css']
} )
export class SubmissionsComponent implements OnInit {
  spinnerComp = new SpinnerComponent();
  submissions = [];
  totalSubmissions;
  spinner = true;
  checkboxStatus = false;
  actionBarDisabled = true;
  selectedItems = [];
  submissionsToDisplay = [];
  currentPage: number;
  labelOffset = 0;
  labelOffsetEnd = 0;
  pageSize: number = 25;

  fields = [
    "E",
    "D",
    "P"
  ];

  constructor( private route: ActivatedRoute, private submissionsService: SubmissionsService, private paginationService: PaginationService, private httpClient: HttpClient, private ngZone: NgZone, private user: UserService ) {
    this.paginationService.page.subscribe( page => {
      this.currentPage = page;
      this.submissionsToDisplay = this.setSubmissionsToDisplay( this.currentPage, this.pageSize );
    } );
  }

  ngOnInit() {
    this.setSubmissionsToDisplay( 1, this.pageSize );
    this.route.paramMap
      .subscribe( params => {
        let itemId = +params.get( 'itemId' );
        let retVal;
        const headers = new HttpHeaders( {
          'Content-Type': 'application/json'
        } );
        var bodyData = '{"formId":"' + itemId + '"}';
        this.httpClient.post<any>( "/mvc/Forms/GetFormSubmissionsCount", JSON.parse( bodyData ), { headers: headers } ).subscribe( ( res ) => {
          retVal = res;
          this.ngZone.run( () => {
            this.totalSubmissions = retVal;
          } );
        } );
        this.spinner = false;
      } );
  }

  allToggle() {
    this.checkboxStatus = $( ".checkbox-custom.submissions-all-checkbox" ).is( ':checked' );
    if ( !this.checkboxStatus ) {
      this.actionBarDisabled = true;
      this.selectedItems = [];
    } else {
      this.actionBarDisabled = false;
      this.selectedItems = this.submissions;
    }
  }

  checkboxClick( e, s ) {
    if ( $( e.toElement ).is( ':checked' ) ) {
      this.addItem( s );
      this.actionBarDisabled = false;
    } else {
      this.removeItem( s );
      this.actionBarDisabled = true;
    }
  }

  addItem( item ) {
    this.selectedItems.push( item );
  }

  removeItem( item ) {
    let i = this.selectedItems.indexOf( item );
    if ( i > -1 ) {
      this.selectedItems.splice( i, 1 );
    }
  }

  setSubmissionsToDisplay( page, offset ) {
    let pageOffset = page * offset;
    pageOffset = pageOffset - offset;
    this.labelOffset = pageOffset + 1;
    this.labelOffsetEnd = pageOffset + offset;
    let startIndex = 0 + ( page * 10 ); //inclusive
    let endIndex = 10 + ( page * 10 ); //exclusive
    let items = this.submissions;//.slice(startIndex, endIndex);

    this.route.paramMap
      .subscribe( params => {
        let itemId = +params.get( 'itemId' );
        let retVal;
        const headers = new HttpHeaders( {
          'Content-Type': 'application/json'
        } );
        var bodyData = '{"formId":"' + itemId + '", "offset": "' + offset + '","pageOffset": "' + pageOffset + '"}';
        this.httpClient.post<any>( "/mvc/Forms/GetFormSubmissionsOffsetFast", JSON.parse( bodyData ), { headers: headers } ).subscribe( ( res ) => {
          retVal = res;
          this.ngZone.run( () => {
            items = retVal;
            this.submissions = retVal;
            this.submissionsToDisplay = retVal;
          } );
        } );
        this.spinner = false;
      } );
    return items;
  }

  parseISOLocal( s ) {
    var formattedDate = new Date( s + "Z" );
    var b = s.split( /\D/ );
    //var formattedDate = new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
    var d = ( '0' + (formattedDate.getDate() ) ).slice( -2 );
    var mon =  ( '0' + (formattedDate.getMonth()+1) ).slice( -2 );
    //mon += 1;  // JavaScript months are 0-11
    var y = formattedDate.getFullYear();

    
    var h = ( '0' + ( formattedDate.getHours() ) ).slice( -2 );
    var m = ( '0' + ( formattedDate.getMinutes() ) ).slice( -2 );
    var sec = ( '0' + ( formattedDate.getSeconds() ) ).slice( -2 );

    //return s
    //return now;
    
    return y + "-" + mon + "-" + d + " " + h + ":" + m + ":" + sec;
    
    //return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
  }

  getDataTableData( obj, keyVal ) {
    var retString = "";
    $.each( keyVal, function( index, keyArray ) {
      $.each( obj.fields, function( key, valueObj ) {
        if( valueObj[ "name" ] == keyArray )
        {
          retString = valueObj[ "value" ];
          return false;
        }
      } );
      if( retString != "" ) {
        return false;
      }
    } );
    return retString;
  }

  pageChange( page ) {
    this.currentPage = page;
    this.setSubmissionsToDisplay( this.currentPage, this.pageSize );
  }

  pageSizeChange( size ) {
    this.pageSize = size;
    this.setSubmissionsToDisplay( 1, this.pageSize );
  }

}
