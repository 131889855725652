import { Component, OnInit, AfterViewInit, NgZone, HostListener } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FolderListService } from '../folder-list/folder-list.service';

@Component( {
  selector: 'forms-dashboard',
  templateUrl: './forms-dashboard.component.html',
  styleUrls: [ './forms-dashboard.component.css' ]
} )
export class FormsDashboardComponent implements OnInit {
  title = "Forms";
  items = []; // Retrieved from forms service
  spinner = true;
  listUnstyled = true;

  constructor( private folderlistService: FolderListService, private httpClient: HttpClient, private ngZone: NgZone ) {

  }

  ngOnInit() {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json'
    } );
    var bodyData = '{"associatedGroup":"' + localStorage.getItem( 'guid' ) + '"}';
    this.httpClient.post<any>( "/mvc/forms/GetForms/", JSON.parse( bodyData ), { headers: headers } ).subscribe( ( res ) => {
      this.ngZone.run( () => {
        //this.items = res;
        this.spinner = false;
        this.folderlistService.foldersReselect();
      } );
    } );
    this.folderlistService.selectedFolderChange.subscribe( selectedFolder => {      
      var bodyData = '{"associatedGroup":"' + localStorage.getItem( 'guid' ) + '", "folderId":"' + selectedFolder.id + '"}';
      this.httpClient.post<any>( "/mvc/forms/GetFormsByFolder/", JSON.parse( bodyData ), { headers: headers } ).subscribe( ( res ) => {
        this.ngZone.run( () => {
          if ( res.length <= 0 )
          {
            this.items = [];
          }else {
            this.items = res;
            this.spinner = false;
          }
        } );
      } );
      //this.folderName = selectedFolder;
      //this.selectedFolder = selectedFolder;
    } );
  }

  ngAfterViewInit() {
    
  }

  formatItemDate( d ) {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    let month = d.substring( 5, 7 );
    if ( month.substring( 0, 1 ) == '0' ) {
      month = month.substring( 1 );
    }

    let day = d.substring( 8, 10 );
    let monthName = monthNames[ month - 1 ];
    let year = d.substring( 0, 4 );
    let newDate = monthName + " " + day + ", " + year;
    return newDate;
  }

}


