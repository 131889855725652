import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.css'],
  host: {
    class: `pf--directive_wrap`
}
})
export class FormSettingsComponent implements OnInit {

  @Input('formObj') formObj;

  constructor() { }

  ngOnInit() {
  }

}
