import { Component, OnInit, NgZone, ViewChildren, QueryList } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import $ from 'jquery';

@Component({
  selector: 'share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {

  subdomainConfirmed = false;
  viewMode = 'start';
  subDomainUrl: string;
  formUrl = "https://www.theproforms.com";
  urlCopied = false;
  formTitle = "Test Title";
  qrcodeLink = 'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=https%3A%2F%2Fyeet.formstack.com%2Fforms%2Fform1&choe=UTF-8';

  constructor(private route: ActivatedRoute, private httpClient:HttpClient, private ngZone:NgZone) { }

  ngOnInit() {
    if(!this.subdomainConfirmed) {
      this.getSubDomain();
    }
  }

  changeViewMode(viewMode) {
    this.viewMode = viewMode;
  }

  createSubdomain(sd) {
    console.log("Subdomain: " + sd);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    var bodyData = '{"userId":"' + localStorage.getItem('userId') + '","SubDomainUrl":"' + sd + '"}';
    this.httpClient.post<any>("/mvc/accounts/UpdateSubDomainUrl/",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
      this.ngZone.run( () => {
        $("#confirmSubdomainModal").modal("hide");
              
      });
    });
  }

  getSubDomain() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let itemId = -1;
    this.route.paramMap.subscribe(params => {
      itemId = +params.get('itemId');
      var bodyData = '{"userId":"' + localStorage.getItem('userId') + '"}';
      this.httpClient.post<any>("/mvc/accounts/GetSubdomain/",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
        this.ngZone.run( () => {
          this.subDomainUrl = res[0].SubDomainUrl;
          $(".subDomainUrl-input").val(res[0].SubDomainUrl);
          $(".subDomainUrl-input").attr("data-value",res[0].SubDomainUrl);
          if(!this.subDomainUrl) {
            $("#confirmSubdomainModal").modal("show");
          }
        });
      });
    });
  }

  copyUrl(input) {
    /* Select the text field */
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
  
    /* Copy the text inside the text field */
    document.execCommand("copy");
  
    /* Change component property to copied */
    this.urlCopied =  true;
  }

}
