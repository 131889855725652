import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/services/search.service';

@Component({
  selector: 'dashboard-search',
  templateUrl: './dashboard-search.component.html',
  styleUrls: ['./dashboard-search.component.css']
})
export class DashboardSearchComponent implements OnInit {

  searchValue;

  constructor(private formSearchService: SearchService) { }

  ngOnInit() {
  }

  searchValueEntered() {
    this.formSearchService.search(this.searchValue);
  }

}
