import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'submissions-filter',
  templateUrl: './submissions-filter.component.html',
  styleUrls: ['./submissions-filter.component.css']
})
export class SubmissionsFilterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
