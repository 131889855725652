import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pf-dropdown',
  templateUrl: './pf-dropdown.component.html',
  styleUrls: ['./pf-dropdown.component.css']
})
export class PfDropdownComponent implements OnInit {
  open = false;
  listActive: string;

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.open = !this.open;
  }

}
