import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pf-input-btn',
  templateUrl: './pf-input-btn.component.html',
  styleUrls: ['./pf-input-btn.component.css']
})
export class PfInputBtnComponent implements OnInit {

  @Input('variant') variant: string;

  constructor() { }

  ngOnInit() {
  }

}
