import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {
    
  allSubmissions = [
    {
      submissionId: 1,
      formId: 1,
      fields: [
        {
            name: "Date/Time Submitted",
            value: "2019-08-09 17:31:27",
            showInTable: true
        },
        {
            name: "Preffered Clinic",
            value: "Tyler, Tx",
            showInTable: true
        },
        {
            name: "First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "Street Address",
            value: "5508 West Orlando Circle",
            showInTable: false
        },
        {
            name: "City",
            value: "Tulsa",
            showInTable: false
        },
        {
            name: "State",
            value: "OK",
            showInTable: false
        },
        {
            name: "Zipcode",
            value: "74011",
            showInTable: false
        },
        {
            name: "Date of Birth",
            value: "1999-02-02",
            showInTable: true
        },
        {
            name: "Gender",
            value: "Male",
            showInTable: true
        },
        {
            name: "Email Address",
            value: "braedensellers@gmail.com",
            showInTable: false
        },
        {
            name: "Phone Number",
            value: "9187202214",
            showInTable: false
        },
        {
            name: "Was Referred",
            value: "No",
            showInTable: false
        },
        {
            name: "Reffered By",
            value: "",
            showInTable: false
        },
        {
            name: "Promotions",
            value: "No Thank You",
            showInTable: false
        },
        {
            name: "Medical History",
            value: "ADD/ADHD",
            showInTable: false
        },
        {
            name: "Heart Attack Date",
            value: "",
            showInTable: false
        },
        {
            name: "Stroke Date",
            value: "",
            showInTable: false
        },
        {
            name: "Medical History Other",
            showInTable: false
        },
        {
            name: "Surgical History",
            value: "None",
            showInTable: false
        },
        {
            name: "Surgical History Other",
            value: "",
            showInTable: false
        },
        {
            name: "Social History",
            value: "None",
            showInTable: false
        },
        {
            name: "Alcohol Consumption",
            value: "",
            showInTable: false
        },
        {
            name: "Illicit Drugs",
            value: "",
            showInTable: false
        },
        {
            name: "Review of Symptoms",
            value: "None",
            showInTable: false
        },
        {
            name: "Eating Habits",
            value: "None",
            showInTable: false
        },
        {
            name: "Physical Activity",
            value: "",
            showInTable: false
        },
        {
            name: "Allergies to Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Current Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Acknowledgement",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Signature",
            value: "Braeden Sellers",
            showInTable: false
        },
        {
            name: "Printed First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Printed Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "18 Years or Older",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Guardian Signature",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian First Name",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian Last Name",
            value: "",
            showInTable: false
        },
      ],
      dateSubmitted: "2019-08-07 18:13:58",
      fullname: "Braeden Sellers",
      dateOfBirth: "1999-02-02",
      preferredClinic: "Tyler, TX",
      gender: "Male",
    },
    {
      submissionId: 2,
      formId: 1,
      fields: [
        {
            name: "Date/Time Submitted",
            value: "2019-10-02 17:31:27",
            fieldType: "date",
            showInTable: true
        },
        {
            name: "Preffered Clinic",
            value: "Plano, TX",
            fieldType: {
                type: "radio",
                options: [
                    "Plano, TX",
                    "Tyler, TX"
                ],
            },
            showInTable: true
        },
        {
            name: "First Name",
            value: "Brandon",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Last Name",
            value: "Smith",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Street Address",
            value: "5508 West Orlando Circle",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "City",
            value: "Tulsa",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "State",
            value: "OK",
            fieldType: {
                type: "select",
                options: [
                    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ],
            },
            showInTable: false
        },
        {
            name: "Zipcode",
            value: "74011",
            fieldType: {
                type: "text",
                maxlength: 5,
            },
            showInTable: false
        },
        {
            name: "Date of Birth",
            value: "1999-02-02",
            fieldType: "date",
            showInTable: true
        },
        {
            name: "Gender",
            value: "Male",
            fieldType: {
                type: "radio",
                options: [
                    "Male",
                    "Female"
                ],
            },
            showInTable: true
        },
        {
            name: "Email Address",
            value: "braedensellers@gmail.com",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Phone Number",
            value: "9187202214",
            fieldType: {
                type: "text",
                maxlength: 10,
            },
            showInTable: false
        },
        {
            name: "Was Referred",
            value: "No",
            fieldType: {
                type: "radio",
                options: [
                    "Yes",
                    "No"
                ],
            },
            showInTable: false
        },
        {
            name: "Reffered By",
            value: "",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Promotions",
            value: "Email",
            fieldType: {
                type: "checkbox-group",
                options: [
                    "No Thank You",
                    "Email",
                    "Text (carrier charges may apply)",
                    "Mail"
                ],
            },
            showInTable: false
        },
        {
            name: "Medical History",
            value: "ADD/ADHD",
            fieldType: {
                type: "checkbox-group",
                options: [
                    "None",
                    "High Blood Pressure",
                    "Heart Attack",
                    "Diabetes Mellitus",
                    "Hypothyroid",
                    "Hyperthyroid",
                    "Reflux",
                    "Glaucoma",
                    "Coronary Artery Disease",
                    "Stroke",
                    "High Cholesterol/Triglycerides",
                    "Congestive Heart Failure",
                    "ADD/ADHD",
                    "Medical History Other (Please explain):",
                ],
            },
            showInTable: false
        },
        {
            name: "Heart Attack Date",
            value: "",
            fieldType: "date",
            showInTable: false
        },
        {
            name: "Stroke Date",
            value: "",
            fieldType: "date",
            showInTable: false
        },
        {
            name: "Medical History Other",
            value: "",
            fieldType: {
                type: "text",
                maxlength: 250
            },
            showInTable: false
        },
        {
            name: "Surgical History",
            value: "None",
            fieldType: {
                type: "checkbox-group",
                options: [
                    "None",
                    "Hysterectomy",
                    "Tubal Ligation",
                    "Gallbladder Removal",
                    "Appendectomy",
                    "Coronary Stents",
                    "Coronary Artery Bypass Graft",
                    "Carotid Endarterectomy",
                    "C-Section",
                    "Surgical History Other (Please explain):",
                ],
            },
            showInTable: false
        },
        {
            name: "Surgical History Other",
            value: "",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Social History",
            value: "None",
            fieldType: {
                type: "checkbox-group",
                options: [
                    "None",
                    "Cigaretts",
                    "Smokeless Tobacco",
                    "Alcohol Consumption",
                    "Illicit Drugs:",
                ],
            },
            showInTable: false
        },
        {
            name: "Alcohol Consumption",
            value: "",
            fieldType: {
                type: "radio",
                options: [
                    "Infrequent Social Drinking",
                    "Frequent Small Amounts",
                    "Frequent Large Amounts"
                ],
            },
            
            showInTable: false
        },
        {
            name: "Illicit Drugs",
            value: "",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Review of Symptoms",
            value: "None",
            fieldType: {
                type: "checkbox-group",
                options: [
                    "None",
                    "Swelling",
                    "Fatigue",
                    "Skin",
                    "Excess Weight",
                ],
            },
            showInTable: false
        },
        {
            name: "Eating Habits",
            value: "None",
            fieldType: {
                type: "checkbox-group",
                options: [
                    "None",
                    "Large Portions",
                    "Sweets/Snacks",
                    "Carbonated Drinks",
                ],
            },
            showInTable: false
        },
        {
            name: "Physical Activity",
            value: "",
            fieldType: {
                type: "checkbox-group",
                options: [
                   "Walk or Other Exercise 1 hr daily, 5 days weekly"
                ],
            },
            showInTable: false
        },
        {
            name: "Allergies to Medications",
            value: "",
            fieldType: {
                type: "text",
                maxlength: 250,
            },
            showInTable: false
        },
        {
            name: "Current Medications",
            value: "",
            fieldType: {
                type: "text",
                maxlength: 250,
            },
            showInTable: false
        },
        {
            name: "Acknowledgement",
            value: "Yes",
            fieldType: {
                type: "checkbox-group",
                options: [
                   "Yes"
                ],
            },
            showInTable: false
        },
        {
            name: "Signature",
            value: "Braeden Sellers",
            fieldType: "signature",
            showInTable: false
        },
        {
            name: "Printed First Name",
            value: "Braeden",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Printed Last Name",
            value: "Sellers",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "18 Years or Older",
            value: "Yes",
            fieldType: {
                type: "radio",
                options: [
                    "Yes",
                    "No"
                ],
            },
            
            showInTable: false
        },
        {
            name: "Guardian Signature",
            value: "",
            fieldType: "uneditable",
            showInTable: false
        },
        {
            name: "Guardian First Name",
            value: "",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
        {
            name: "Guardian Last Name",
            value: "",
            fieldType: {
                type: "text",
            },
            showInTable: false
        },
      ],
      dateSubmitted: "2019-08-10 18:13:58",
      fullname: "Tyler Adams",
      dateOfBirth: "1972-11-09",
      preferredClinic: "Tyler, TX",
      gender: "Male",
    },
    {
      submissionId: 3,
      formId: 1,
      fields: [
        {
            name: "Date/Time Submitted",
            value: "2019-08-09 17:31:27",
            showInTable: true
        },
        {
            name: "Preffered Clinic",
            value: "Tyler, Tx",
            showInTable: true
        },
        {
            name: "First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "Street Address",
            value: "5508 West Orlando Circle",
            showInTable: false
        },
        {
            name: "City",
            value: "Tulsa",
            showInTable: false
        },
        {
            name: "State",
            value: "OK",
            showInTable: false
        },
        {
            name: "Zipcode",
            value: "74011",
            showInTable: false
        },
        {
            name: "Date of Birth",
            value: "1999-02-02",
            showInTable: true
        },
        {
            name: "Gender",
            value: "Male",
            showInTable: true
        },
        {
            name: "Email Address",
            value: "braedensellers@gmail.com",
            showInTable: false
        },
        {
            name: "Phone Number",
            value: "9187202214",
            showInTable: false
        },
        {
            name: "Was Referred",
            value: "No",
            showInTable: false
        },
        {
            name: "Reffered By",
            value: "",
            showInTable: false
        },
        {
            name: "Promotions",
            value: "No Thank You",
            showInTable: false
        },
        {
            name: "Medical History",
            value: "ADD/ADHD",
            showInTable: false
        },
        {
            name: "Heart Attack Date",
            value: "",
            showInTable: false
        },
        {
            name: "Stroke Date",
            value: "",
            showInTable: false
        },
        {
            name: "Medical History Other",
            showInTable: false
        },
        {
            name: "Surgical History",
            value: "None",
            showInTable: false
        },
        {
            name: "Surgical History Other",
            value: "",
            showInTable: false
        },
        {
            name: "Social History",
            value: "None",
            showInTable: false
        },
        {
            name: "Alcohol Consumption",
            value: "",
            showInTable: false
        },
        {
            name: "Illicit Drugs",
            value: "",
            showInTable: false
        },
        {
            name: "Review of Symptoms",
            value: "None",
            showInTable: false
        },
        {
            name: "Eating Habits",
            value: "None",
            showInTable: false
        },
        {
            name: "Physical Activity",
            value: "",
            showInTable: false
        },
        {
            name: "Allergies to Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Current Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Acknowledgement",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Signature",
            value: "Braeden Sellers",
            showInTable: false
        },
        {
            name: "Printed First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Printed Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "18 Years or Older",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Guardian Signature",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian First Name",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian Last Name",
            value: "",
            showInTable: false
        },
      ],
      dateSubmitted: "2019-09-21 18:13:58",
      fullname: "Brandon Smith",
      dateOfBirth: "1972-01-10",
      preferredClinic: "Plano, TX",
      gender: "Male",
    },
    {
      submissionId: 1,
      formId: 2,
      fields: [
        {
            name: "Date/Time Submitted",
            value: "2019-08-09 17:31:27",
            showInTable: true
        },
        {
            name: "Preffered Clinic",
            value: "Tyler, Tx",
            showInTable: true
        },
        {
            name: "First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "Street Address",
            value: "5508 West Orlando Circle",
            showInTable: false
        },
        {
            name: "City",
            value: "Tulsa",
            showInTable: false
        },
        {
            name: "State",
            value: "OK",
            showInTable: false
        },
        {
            name: "Zipcode",
            value: "74011",
            showInTable: false
        },
        {
            name: "Date of Birth",
            value: "1999-02-02",
            showInTable: true
        },
        {
            name: "Gender",
            value: "Male",
            showInTable: true
        },
        {
            name: "Email Address",
            value: "braedensellers@gmail.com",
            showInTable: false
        },
        {
            name: "Phone Number",
            value: "9187202214",
            showInTable: false
        },
        {
            name: "Was Referred",
            value: "No",
            showInTable: false
        },
        {
            name: "Reffered By",
            value: "",
            showInTable: false
        },
        {
            name: "Promotions",
            value: "No Thank You",
            showInTable: false
        },
        {
            name: "Medical History",
            value: "ADD/ADHD",
            showInTable: false
        },
        {
            name: "Heart Attack Date",
            value: "",
            showInTable: false
        },
        {
            name: "Stroke Date",
            value: "",
            showInTable: false
        },
        {
            name: "Medical History Other",
            showInTable: false
        },
        {
            name: "Surgical History",
            value: "None",
            showInTable: false
        },
        {
            name: "Surgical History Other",
            value: "",
            showInTable: false
        },
        {
            name: "Social History",
            value: "None",
            showInTable: false
        },
        {
            name: "Alcohol Consumption",
            value: "",
            showInTable: false
        },
        {
            name: "Illicit Drugs",
            value: "",
            showInTable: false
        },
        {
            name: "Review of Symptoms",
            value: "None",
            showInTable: false
        },
        {
            name: "Eating Habits",
            value: "None",
            showInTable: false
        },
        {
            name: "Physical Activity",
            value: "",
            showInTable: false
        },
        {
            name: "Allergies to Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Current Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Acknowledgement",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Signature",
            value: "Braeden Sellers",
            showInTable: false
        },
        {
            name: "Printed First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Printed Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "18 Years or Older",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Guardian Signature",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian First Name",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian Last Name",
            value: "",
            showInTable: false
        },
      ],
      dateSubmitted: "2018-09-18 20:08:24",
      fullname: "Test Guy",
      dateOfBirth: "1981-11-28",
      preferredClinic: "Plano, TX",
      gender: "Female",
    },
    {
      submissionId: 2,
      formId: 2,
      fields: [
        {
            name: "Date/Time Submitted",
            value: "2019-08-09 17:31:27",
            showInTable: true
        },
        {
            name: "Preffered Clinic",
            value: "Tyler, Tx",
            showInTable: true
        },
        {
            name: "First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "Street Address",
            value: "5508 West Orlando Circle",
            showInTable: false
        },
        {
            name: "City",
            value: "Tulsa",
            showInTable: false
        },
        {
            name: "State",
            value: "OK",
            showInTable: false
        },
        {
            name: "Zipcode",
            value: "74011",
            showInTable: false
        },
        {
            name: "Date of Birth",
            value: "1999-02-02",
            showInTable: true
        },
        {
            name: "Gender",
            value: "Male",
            showInTable: true
        },
        {
            name: "Email Address",
            value: "braedensellers@gmail.com",
            showInTable: false
        },
        {
            name: "Phone Number",
            value: "9187202214",
            showInTable: false
        },
        {
            name: "Was Referred",
            value: "No",
            showInTable: false
        },
        {
            name: "Reffered By",
            value: "",
            showInTable: false
        },
        {
            name: "Promotions",
            value: "No Thank You",
            showInTable: false
        },
        {
            name: "Medical History",
            value: "ADD/ADHD",
            showInTable: false
        },
        {
            name: "Heart Attack Date",
            value: "",
            showInTable: false
        },
        {
            name: "Stroke Date",
            value: "",
            showInTable: false
        },
        {
            name: "Medical History Other",
            showInTable: false
        },
        {
            name: "Surgical History",
            value: "None",
            showInTable: false
        },
        {
            name: "Surgical History Other",
            value: "",
            showInTable: false
        },
        {
            name: "Social History",
            value: "None",
            showInTable: false
        },
        {
            name: "Alcohol Consumption",
            value: "",
            showInTable: false
        },
        {
            name: "Illicit Drugs",
            value: "",
            showInTable: false
        },
        {
            name: "Review of Symptoms",
            value: "None",
            showInTable: false
        },
        {
            name: "Eating Habits",
            value: "None",
            showInTable: false
        },
        {
            name: "Physical Activity",
            value: "",
            showInTable: false
        },
        {
            name: "Allergies to Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Current Medications",
            value: "",
            showInTable: false
        },
        {
            name: "Acknowledgement",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Signature",
            value: "Braeden Sellers",
            showInTable: false
        },
        {
            name: "Printed First Name",
            value: "Braeden",
            showInTable: false
        },
        {
            name: "Printed Last Name",
            value: "Sellers",
            showInTable: false
        },
        {
            name: "18 Years or Older",
            value: "Yes",
            showInTable: false
        },
        {
            name: "Guardian Signature",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian First Name",
            value: "",
            showInTable: false
        },
        {
            name: "Guardian Last Name",
            value: "",
            showInTable: false
        },
      ],
      dateSubmitted: "2018-10-18 20:08:24",
      fullname: "Test Dude",
      dateOfBirth: "1975-01-10",
      preferredClinic: "Tyler, TX",
      gender: "Male",
    },
  ];

  constructor(private httpClient: HttpClient) { }
  
  
  GetData() {
      let arrVal: any;
        
      arrVal = this.httpClient.get<any>("https://portal.exphs.com/webservice%20center/getemrdata.asmx/GetFormSubmissions"),map(response => response)
      //arrVal = this.http.get("https://portal.exphs.com/webservice%20center/getemrdata.asmx/GetFormSubmissions").pipe(map(res => res)); 
      console.log(arrVal);
      return arrVal;
  }
  
  getSubs()
  {
    let retVal;
    this.httpClient.get<Array<Object>>("https://portal.exphs.com/webservice%20center/getemrdata.asmx/GetFormSubmissions").subscribe((res)=>{
        console.log(res);
        retVal = res;
        /*this.ngZone.run( () => {
          this.submissions = retVal;
       });*/
       
    });
    return retVal;
  }

  getAllSubmissions(itemId) {
    let pushSubmissions = [];
    for(let i = 0; i < this.allSubmissions.length; i++) {
      if(this.allSubmissions[i].formId == itemId)
        pushSubmissions.push(this.allSubmissions[i]);
    }
    return pushSubmissions
  }

  getSubmission(itemId, submissionId) {
    let submissions = this.getAllSubmissions(itemId);
    let pushSubmission = [];
    let found = false;
    if(!found) {
      for(let i = 0; i < submissions.length; i++) {
        if(submissions[i].submissionId == submissionId) 
            pushSubmission = submissions[i];
            found = true;
      }
    }
    return pushSubmission
  }

}
