import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-checkbox-toggle',
  styleUrls: ['./dashboard-checkbox-toggle.component.css'],
  template: ``
})
export class DashboardCheckboxToggleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
