import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'submissions-download-all',
  templateUrl: './submissions-download-all.component.html',
  styleUrls: ['./submissions-download-all.component.css']
})
export class SubmissionsDownloadAllComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  downloadAll() {
    alert('download all (not functional yet)');
  }

}
