import { UserService } from './../../services/user.service';
import { ProfileComponent } from './../profile/profile.component';
import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import $ from 'jquery';

@Component({
  selector: 'pf-sign-in',
  templateUrl: './proforms-sign-in.component.html',
  styleUrls: ['./proforms-sign-in.component.css']
})
export class ProformsSignInComponent implements OnInit {

  submitFail: boolean = false;

  constructor( private router: Router, private httpClient: HttpClient, private users: UserService ) {
  }

  ngOnInit() {
    // Event Listener for focus in any textbox in the login form
    $( "#pf-login-form input" ).focus( this.onFormFocus() );
  }

  // Runs when the login form is validly submitted
  submit( f ) {
    let check: boolean;
    console.log( "Login check" );
    //console.log( this.users.checkLogin( f ) );
    if ( this.users.checkLogin( f ) === "Failure" ) {
      check = false;
    } else {
      console.log('test');
      check = true;
    }
    /*if ( check ) {
      this.users.loginUser( f );
    } else {
      this.submitFail = true;
    }*/
    //let check = ( this.users.checkLogin( f ) === "Failure" ? false : true );
    //check ? this.users.loginUser( f ) : this.submitFail = true;
  }

  // Event should fire when a textbox in the form is interacted with
  onFormFocus() {
    this.submitFail = false;
  }

}
