import {
  Component,
  OnInit,
  Input,
  Renderer,
  HostListener,
} from "@angular/core";
import { FolderListComponent } from "./../folder-list/folder-list.component";
import { FolderListService } from "./../folder-list/folder-list.service";
import { AppComponent } from "./../app.component";
import $ from "jquery";
@Component({
  selector: "dashboard-folder",
  templateUrl: "./dashboard-folder.component.html",
  styleUrls: ["./dashboard-folder.component.css"],
})
export class DashboardFolderComponent implements OnInit {
  @Input("folder") folderName: String;
  @Input("isSelected") isSelected: boolean;
  @Input("icon") icon: String;
  @Input("hasSubfolder") hasSubfolder: boolean;
  selectedFolder: string;
  folders;
  isExpanded: boolean;

  public wasInside = false;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  @Input("folderObj") folderObj: any;

  constructor(
    private appComponent: AppComponent,
    private render: Renderer,
    private folderlistService: FolderListService,
    private folderlistComponent: FolderListComponent
  ) {}

  ngOnInit() {
    this.isExpanded = this.hasSubfolder;
  }

  isEmptyObject(obj) {
    //console.log(obj);
    return obj && Object.keys(obj).length === 0;
  }

  @HostListener("document:click")
  clickout() {
    if (!this.folderlistComponent.wasInside) {
      this.folderlistComponent.contextmenu = false;
      this.appComponent.contextmenu = false;
    }
    this.folderlistComponent.wasInside = false;
  }

  onClick(folder, event) {
    console.log(folder.name);
    this.folderlistService.toggle(folder);
    $(".db-folder-item").removeClass("folder-selected");
    $(event.target).closest(".db-folder-item").addClass("folder-selected");
    event.preventDefault();
    event.stopPropagation();
    this.folderlistComponent.wasInside = true;
    this.folderlistComponent.contextmenu = false;
  }

  getIsExpanded($event) {
    this.isExpanded = $event;
  }

  onRightClick(folder, event) {
    var xj = event.pageX - $("body").offset().left;
    var yj = event.clientY; //- $('body').offset().top;
    this.appComponent.contextmenuX = xj;
    this.appComponent.contextmenuY = yj;
    this.appComponent.folderObj = folder;
    this.appComponent.contextmenu = true;
    console.log(folder.name);
    //this.folderlistService.toggle(folder);
    //$(".db-folder-item").removeClass("folder-selected");
    //$(event.target).closest(".db-folder-item").addClass("folder-selected");
    event.preventDefault();
    event.stopPropagation();
  }

  disableContextMenu() {
    this.folderlistComponent.contextmenu = false;
  }
}
