import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'submissions-share',
  templateUrl: './submissions-share.component.html',
  styleUrls: ['./submissions-share.component.css']
})
export class SubmissionsShareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
