import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dashboard-btn',
  templateUrl: './dashboard-btn.component.html',
  styleUrls: ['./dashboard-btn.component.css']
})
export class DashboardBtnComponent implements OnInit {
  @Input() dbBtnText: string;
  @Input() dbBtnSize: string;
  @Input() dbBtnVariant: string;
  @Input() dbBtnBg: string;
  @Input() dbBtnLink: string;
  @Input() dbBtnIcon: string;
  values;

  constructor() { }

  ngOnInit() {
    if(this.dbBtnIcon)
    {
      this.dbBtnIcon = "&nbsp;<i class='material-icons btn-icon'>" + this.dbBtnIcon + '</i>';
    }
  }

  clickMessage = '';

  onClickMe(e) {
    this.clickMessage = 'You are my hero!';
  }

}
