import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pf-dashboard-grid-themes',
  templateUrl: './pf-dashboard-grid-themes.component.html',
  styleUrls: ['./pf-dashboard-grid-themes.component.css']
})
export class PfDashboardGridThemesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
