import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'pf-dashboard',
  templateUrl: './pf-dashboard.component.html',
  styleUrls: ['./pf-dashboard.component.css']
})
export class PfDashboardComponent implements OnInit {

  forms = [];

  constructor(private httpClient: HttpClient, private ngZone: NgZone) { }

  ngOnInit() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    var bodyData = '{"associatedGroup":"' + localStorage.getItem('guid') + '"}';
    this.httpClient.post<any>("/mvc/forms/GetForms/",JSON.parse(bodyData), {headers: headers}).subscribe((res)=>{
      this.ngZone.run( () => {
        this.forms = res.slice(0, 5);
      });
    });
  }

}
