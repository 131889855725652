import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  currentPage: number = 1;

  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() changePageSize: EventEmitter<any> = new EventEmitter();
  @Output() page: EventEmitter<any> = new EventEmitter();
  @Output() pageSize: EventEmitter<any> = new EventEmitter();

  constructor() { }

  setChangePage(pageVal) {
    this.currentPage = pageVal;
    this.page.emit(pageVal);
  }

  setPageSize(pageSizeVal) {
    this.pageSize.emit(pageSizeVal);
  }

}
