import { Component, OnInit, Input, NgZone, HostListener } from "@angular/core";
//import { FoldersService } from './../../services/folders.service';
import { FolderListService } from "./folder-list.service";
import { AppComponent } from "./../app.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import $ from "jquery";
import "bootstrap";

@Component({
  selector: "folder-list",
  templateUrl: "./folder-list.component.html",
  styleUrls: ["./folder-list.component.css"],
})
export class FolderListComponent implements OnInit {
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  selectedFolder: string;
  folders;
  folderObj;
  folderModalObj;
  public wasInside = false;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  spinner = true;
  unarchived = {
    id: -1,
    name: "Unsorted Forms",
    description:
      "Your unsorted forms don’t have a home. Don’t worry, you can create a folder to stay organized and give other users access to your forms!",
    isSelected: 0,
    icon: "help",
    subfolders: [],
  };
  archived = {
    id: -2,
    name: "Archived Forms",
    description:
      "Your archived forms will be stored here. They are still available, but not active.",
    isSelected: 0,
    icon: "archive",
    subfolders: [],
  };
  deleted = {
    id: -3,
    name: "Deleted Forms",
    description: "Your deleted forms will be stored here.",
    isSelected: 0,
    icon: "delete",
    subfolders: [],
  };

  constructor(
    private appComponent: AppComponent,
    private httpClient: HttpClient,
    private folderlistService: FolderListService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.folders = this.folderlistService.getFolders();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    var bodyData = '{"associatedGroup":"' + localStorage.getItem("guid") + '"}';
    this.httpClient
      .post<any>("/mvc/folders/GetFolders/", JSON.parse(bodyData), {
        headers: headers,
      })
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.Folder === undefined) this.folders = [];
          else this.folders = res.Folder;

          this.folders.push(this.unarchived);
          this.folders.push(this.archived);
          this.folders.push(this.deleted);
          this.folderlistService.toggle(this.folders[0]);
          this.folders[0].isSelected = 1;
          this.spinner = false;

          console.log('folders!!!');
          console.log(this.folders);
        });
      });

    this.folderlistService.reloadFolders.subscribe((selectedFolder) => {
      $("#createFolderModal").modal("hide");
      $(".modal-backdrop").hide();
      this.updateFolderList();
    });
  }

  loadFolders(selectedIndex) {
    this.folders[selectedIndex].isSelected = 1;
  }

  onClick(folder) {
    this.folderlistService.toggle(folder);
    $(".db-folder-item").removeClass("folder-selected");
    $(event.target).closest(".db-folder-item").addClass("folder-selected");
    this.wasInside = true;
  }

  @HostListener("document:click")
  clickout() {
    if (!this.wasInside) {
      this.contextmenu = false;
      this.appComponent.contextmenu = false;
    }
    this.wasInside = false;
  }

  onRightClick(folder, event) {
    var xj = event.pageX - $("body").offset().left;
    var yj = event.clientY; //- $('body').offset().top;
    this.contextmenuX = xj;
    this.contextmenuY = yj;
    this.folderObj = folder;
    this.contextmenu = true;

    this.appComponent.contextmenuX = xj;
    this.appComponent.contextmenuY = yj;
    this.appComponent.folderObj = folder;
    this.appComponent.contextmenu = true;

    this.folderlistService.toContextMenu(xj, yj, this.contextmenu, folder);
    //$(".db-folder-item").removeClass("folder-selected");
    //$(event.target).closest(".db-folder-item").addClass("folder-selected");
    event.preventDefault();
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  addSubFolder(folderName, folder) {
    var bodyData =
      '{"associatedGroup":"' +
      localStorage.getItem("guid") +
      '", "folderId":"' +
      folder.id +
      '","folderName":"' +
      folderName.value +
      '"}';
    this.httpClient
      .post<any>("/mvc/folders/AddSubFolder/", JSON.parse(bodyData), {
        headers: this.headers,
      })
      .subscribe((res) => {
        $("#contextMenuAddSubFolderModal").modal("hide");
        this.updateFolderList();
      });
  }

  renameFolder(folderName, folder) {
    var bodyData =
      '{"associatedGroup":"' +
      localStorage.getItem("guid") +
      '", "folderId":"' +
      folder.id +
      '","folderName":"' +
      folderName.value +
      '"}';
    this.httpClient
      .post<any>("/mvc/folders/RenameFolder/", JSON.parse(bodyData), {
        headers: this.headers,
      })
      .subscribe((res) => {
        $("#contextMenuRenameFolderModal").modal("hide");
        this.updateFolderList();
      });
  }

  deleteFolder(folder) {
    var bodyData =
      '{"associatedGroup":"' +
      localStorage.getItem("guid") +
      '", "folderId":"' +
      folder.id +
      '"}';
    this.httpClient
      .post<any>("/mvc/folders/DeleteFolder/", JSON.parse(bodyData), {
        headers: this.headers,
      })
      .subscribe((res) => {
        $("#contextMenuDeleteFolderModal").modal("hide");
        this.updateFolderList();
      });
  }

  updateFolderList() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    var bodyData = '{"associatedGroup":"' + localStorage.getItem("guid") + '"}';
    this.httpClient
      .post<any>("/mvc/folders/GetFolders/", JSON.parse(bodyData), {
        headers: headers,
      })
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.folders = res.Folder;
          console.log('folders');
          console.log(this.folders);
          this.folders.push(this.unarchived);
          this.folders.push(this.archived);
          this.folders.push(this.deleted);

          this.folderlistService.toggle(this.folders[0]);
          this.folders[0].isSelected = 1;
        });
      });
  }
}
