import { Injectable, Output, EventEmitter, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UserService } from '../../services/user.service';
import { Enums } from './../enums/enums';

@Injectable()
export class FolderListService {

  bodyData = this.user.getGuid();
  headers = new HttpHeaders( {
    'Content-Type': 'application/json'
  } );

  folders: Array<any>;
  selectedFolder;

  @Output() selectedFolderChange: EventEmitter<string> = new EventEmitter();
  @Output() reloadFolders: EventEmitter<string> = new EventEmitter();
  @Output() reselectFolders: EventEmitter<string> = new EventEmitter();
  @Output() contextData: EventEmitter<string> = new EventEmitter();
  @Output() modalFolder: EventEmitter<string> = new EventEmitter();
  
  constructor( private http: HttpClient, private ngZone: NgZone, private user: UserService ) {
  }

  getFolders() {
    this.http.post<any>( "/mvc/folders/GetFolders/", JSON.parse( this.bodyData ), { headers: this.headers } ).subscribe( ( res ) => {
      this.folders = res.Folder;
      if ( this.folders ) {
        this.toggle( this.folders[ 0 ] );
        this.folders[ 0 ].isSelected = 1;
      } else {
        console.log( "This user does not currently have any forms." );
      }
    } );
    return this.folders;
  }

  toggle( folderString ) {
    this.selectedFolder = folderString;
    this.selectedFolderChange.emit( this.selectedFolder );
  }

  foldersReselect() {
    this.reselectFolders.emit();
  }

  foldersReload() {
    this.reloadFolders.emit();
  }

  toModal( folder ) {
    this.modalFolder.emit( folder );
  }

  toContextMenu( x, y, isVisible, folderObj ) {
    var cmObj;
    cmObj[ "x" ] = x;
    cmObj[ "y" ] = y;
    cmObj[ "isVisible" ] = isVisible;
    cmObj[ "folderObj" ] = folderObj;

    this.contextData.emit( cmObj );
  }
}
