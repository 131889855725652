import { ItemCheckboxesService } from './../../services/item-checkboxes.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FolderListService } from './../folder-list/folder-list.service';
import { ActivatedRoute } from '@angular/router';

import $ from 'jquery';
import 'bootstrap';

@Component({
  selector: 'pf-action-bar',
  templateUrl: './pf-action-bar.component.html',
  styleUrls: ['./pf-action-bar.component.css']
})
export class PfActionBarComponent implements OnInit {
  folderObj;
  checkboxStatus = false;
  selectedIds = [];
  actionBarDisabled = true;
  headers = new HttpHeaders( {
    'Content-Type': 'application/json'
  } );
  folders: Array<any>;
  subdomain: string;

  constructor( private httpClient: HttpClient, private folderlistService: FolderListService, private itemCheckboxesService: ItemCheckboxesService, private route: ActivatedRoute ) {
    this.itemCheckboxesService.change.subscribe( ids => {
      this.selectedIds = ids;
      if ( !this.selectedIds.length ) {
        this.actionBarDisabled = true;
      } else {
        this.actionBarDisabled = false;
      }
    } );
  }

  ngOnInit() {
    this.folderlistService.selectedFolderChange.subscribe( sentFolderObj => {
      this.folderObj = sentFolderObj;
    } );
  }

  addNewForm() {
    console.log('add new form');
    this.folders = this.folderlistService.getFolders();
    this.subdomain = this.getSubDomain();
    $( "#createFormModal" ).modal( "show" );
  }

  createForm2( formObj ) {
    console.log( formObj.value );
    let bodyData = '{"associatedGroup":"' + localStorage.getItem('guid') + '", "userIdCreated":"' + localStorage.getItem('userId') + '", "folderId":"' + formObj.value.formFolder + '","formName":"' + formObj.value.formName + '"}';
    this.httpClient.post<any>("/mvc/forms/CreateForm/",JSON.parse(bodyData), {headers: this.headers}).subscribe((res)=>{
      $("#createFormModal").modal("hide");
      this.folderlistService.toggle( formObj.value.formFolder );
      //this.reloadFolderList();
    });
  }

  createForm( name, desc, url, folder, rFolderObj ) {
    let bodyData = '{"associatedGroup":"' + localStorage.getItem( 'guid' ) + '", "userIdCreated":"' + localStorage.getItem( 'userId' ) + '", "folderId":"' + rFolderObj.id + '","formName":"' + name.value + '"}';
    this.httpClient.post<any>( "/mvc/forms/CreateForm/", JSON.parse( bodyData ), { headers: this.headers } ).subscribe( ( res ) => {
      $( "#createFormModal" ).modal( "hide" );
      this.folderlistService.toggle( rFolderObj );
      //this.reloadFolderList();
    });
  }

  allToggle() {
    this.checkboxStatus = $( ".checkbox-custom.action-bar" ).is( ':checked' );
    this.itemCheckboxesService.mainCheckToggle( this.checkboxStatus );
    if ( !this.checkboxStatus ) {
      this.actionBarDisabled = true;
    } else {
      this.actionBarDisabled = false;
    }
  }

  moveForm() {
    if ( this.actionBarDisabled ) return
  }

  copyForm() {
    if ( this.actionBarDisabled ) return
  }

  archiveForm() {
    if ( this.actionBarDisabled ) return
  }

  deleteForm() {
    if ( this.actionBarDisabled ) return
  }

  getSubDomain() {
    let sd = '';
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json'
    } );
    this.route.paramMap.subscribe( params => {
      var bodyData = '{"userId":"' + localStorage.getItem( 'userId' ) + '"}';
      this.httpClient.post<any>( "/mvc/accounts/GetSubdomain/", JSON.parse( bodyData ), { headers: headers } ).subscribe( ( res ) => {
        sd = res[ 0 ].SubDomainUrl;
      } );
    } );
    return sd;
  }

}
