import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'submissions-delete-all',
  templateUrl: './submissions-delete-all.component.html',
  styleUrls: ['./submissions-delete-all.component.css']
})
export class SubmissionsDeleteAllComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  deleteAll() {
    alert('Deleteing all (not functional yet)');
  }

}
