import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PaginationService } from './../../services/pagination.service'

@Component({
  selector: 'pf-pagination',
  templateUrl: './pf-pagination.component.html',
  styleUrls: ['./pf-pagination.component.css']
})
export class PfPaginationComponent implements OnInit, OnChanges {

  pager: any = {};

  @Input() items;
  @Input() maxPages: number;
  @Input() totalItems: number;
  @Input() initialPage = 1;
  @Input() pageSize = 25;

  @Output() changePage = new EventEmitter<any>(true);

  currentPage: number;
  pages: Array<number>;
  pagesToDisplay: Array<number>;

  constructor(private paginationService: PaginationService) {
    //this.paginationService.setPageSize(this.pageSize);
    this.paginationService.changePage.subscribe(page => { });
    this.paginationService.changePageSize.subscribe(pageSize => { });
  }

  ngOnInit() {
    console.log('on init');
    console.log("total items");
    console.log(this.totalItems);
    // set page if items array isn't empty
    if ( this.items && this.items.length ) {
      console.log('settings items');
      this.setPage( this.initialPage );
    }
    this.currentPage = this.initialPage;
    this.maxPages = Math.ceil( this.totalItems / this.pageSize );
    this.generatePageArray();
    this.generatePagesToDisplay();
  }

  ngOnChanges( changes: SimpleChanges ) {
    // reset page if items array has changed
    if ( changes.items.currentValue !== changes.items.previousValue ) {
      this.setPage( this.initialPage );
    }
  }

  setPage( page: number ) {
    // get new pager object for specified page
    //this.pager = paginate( this.items.length, page, this.pageSize, this.maxPages );

    // get new page of items from items array
    var pageOfItems = this.items.slice( this.pager.startIndex, this.pager.endIndex + 1);

    // call change page functon in parent component
    this.changePage.emit( pageOfItems );
  }

  generatePageArray() {
    this.pages = [];
    for(let i = 1; i <= this.maxPages; i++) {
      this.pages.push(i);
    };
  }

  generatePagesToDisplay() {
    this.pagesToDisplay = [];
    let array = [1, 2, 3, 4, 5];
    let index = this.pages.indexOf(this.currentPage);
    array.forEach(pageIndex => {
      let page = this.currentPage + pageIndex - 1;
      if(index < 2 && index != -1) {
        this.pagesToDisplay.push(page);
      }else if(index < 0) {
        
      }else {
        this.pagesToDisplay.push(page);
      }
    });
  }

  skipToFirst() {

  }

  skipToLast() {

  }

  onPageChange(page) {
    let index = this.pages.indexOf(this.currentPage);
    this.paginationService.setChangePage(page);
    this.currentPage = page;
    this.generatePagesToDisplay();
  }

  onPageNext() {
    if(this.currentPage < this.maxPages) {
      this.paginationService.setChangePage((this.currentPage + 1));
      this.currentPage = this.currentPage + 1;
    }
    this.generatePagesToDisplay();
  }

  onPagePrevious() {
    if(this.currentPage > this.initialPage) {
      this.paginationService.setChangePage((this.currentPage - 1));
      this.currentPage = this.currentPage - 1;
    }
    this.generatePagesToDisplay();
  }

  onPageSizeChange() {
    this.paginationService.setPageSize(this.pageSize);
    this.generatePagesToDisplay();
  }

}
