import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pf-input-addon',
  template: '{{ addonText }}',
  styleUrls: ['./pf-input-addon.component.css']
})
export class PfInputAddonComponent implements OnInit {

  @Input('addonText') addonText: string;

  constructor() { }

  ngOnInit() {
  }

}
