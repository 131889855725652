import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dashboard-checkbox-label',
  templateUrl: './dashboard-checkbox-label.component.html',
  styleUrls: ['./dashboard-checkbox-label.component.css']
})
export class DashboardCheckboxLabelComponent implements OnInit {
  @Output() check = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.check.emit();
  }

}
