import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './../../services/user.service';
import { FormsService } from './../../services/forms.service';
import { FolderListService } from './../folder-list/folder-list.service';
import { SearchService } from 'src/services/search.service';
import { NavbarService } from './../../services/navbar.service';

import $ from 'jquery';
import 'bootstrap';

@Component({
  selector: 'dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})

export class DashboardHomeComponent implements OnInit {
  headers = new HttpHeaders( {
    'Content-Type': 'application/json'
  } );
  bodyData = this.user.getGuid();
  searchValue: string;
  searchResults = [];
  forms;

  constructor( private httpClient: HttpClient, public formsService: FormsService, public navbarService: NavbarService, private folderListService: FolderListService, private formSearchService: SearchService, private ngZone: NgZone, private user: UserService ) {
    this.formSearchService.formSearch.subscribe( searchValue => {
      this.searchValue = searchValue;
      this.getSearchResults();
    } );
    this.forms = this.formsService.getForms();
  }

  ngOnInit() {

  }

  openFolderModal() {
    $( "#createFolderModal" ).modal( "show" );
  }

  createNewFolder( name ) {
    console.log(name);
    this.httpClient.post<any>( "/mvc/folders/CreateFolder/",JSON.parse( this.bodyData ), { headers: this.headers } ).subscribe( ( res ) => {      
      this.folderListService.foldersReload();
    } );
  }

  getSearchResults() {
    let searchResults = [];
    let iSearchVal = this.searchValue.toLowerCase();
    console.log( this.forms );
    $.each( this.forms, function( i, f ) {
      if( f.name.toLowerCase().indexOf( iSearchVal ) >= 0 ) {
        searchResults.push( this );
      }
    } );
    console.log( searchResults );
    
    this.searchResults = searchResults;
    if ( searchResults == [] ) {
      
    }
    this.folderListService.foldersReload();
  }

  getFormCount() {
    let retVal = 0;
    if ( this.forms !== undefined ) {
      retVal = this.forms.length;
    } 
    return retVal;
  }

  getSubmissionCount() {
    let total = 0;
    if ( this.forms !== undefined )
    {
      this.forms.forEach( f => {
        total += f.submissions;
      } );
    }
    return total
  }

}
