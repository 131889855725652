import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'submission-view-item',
  templateUrl: './submission-view-item.component.html',
  styleUrls: ['./submission-view-item.component.css']
})
export class SubmissionViewItemComponent implements OnInit {

  @Input() fieldName: string;
  @Input() fieldType: string;
  @Input() fieldValue;

  constructor() { }

  ngOnInit() {
    console.log(this.fieldType);
  }

}
