import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  @Output() themeOutput: EventEmitter<any> = new EventEmitter();
  visible: boolean;
  theme = 0;

  constructor() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }

  toggle() {
    this.visible = !this.visible;
  }

  toggleDropdown(name) {
    let id = "header__dropdown-" + name;
    var e = document.getElementById(id);
    if(e.classList.contains("ng-hide")) {
      this.showDropdown(name);
    }else{
      this.closeDropdown(name);
    }
  }

  showDropdown(name) {
    let id = "header__dropdown-" + name;
    var e = document.getElementById(id);
    e.classList.remove("ng-hide");
  }

  closeDropdown(name) {
    let id = "header__dropdown-" + name;
    var e = document.getElementById(id);
    e.classList.add("ng-hide");
  }

  toggleTheme() {
    console.log(this.theme);
    if(this.theme == 0) {
      this.theme = 1; // Dark Mode
    }else {
      this.theme = 0; // Light Mode
    }
    this.themeOutput.emit(this.theme);
  }

}
